.not-found-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.content-center {
    text-align: center;
}

.error-code {
    font-size: 96px;
    font-weight: bold;
}

.error-message {
    font-size: 36px;
    font-weight: bold;
}

.error-description {
    font-size: 18px;
    color: gray;
    margin-bottom: 32px;
}

.button-group {
    display: flex;
    gap: 16px;
    justify-content: center;
}

.return-home, .explore {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    transition: background-color 0.3s;
}

.return-home {
    background-color: #0d72ad;
}

.return-home:hover {
    background-color: #1c5cbd;
}

.explore {
    background-color: transparent;
    border: 2px solid black;
    color: black;
}

.explore:hover {
    background-color: rgb(188, 212, 255);
    color: black;
}

