.enhanced-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
}

.footer-logo {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
}

.logo-icon {
    width: 50px;
    height: auto;
}

.logo-text {
    font-size: 18px;
    font-weight: bold;
}   

.footer-text {
    text-align: center;
    font-size: 14px;
    flex-grow: 2;
}

.social-icons {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
}

.social-icons a {
    font-size: 20px;
    transition: color 0.3s ease;
}

.social-icons a:hover {
    transform: scale(1.1);
}

/* Responsive Design */

@media (max-width: 768px) {
    .enhanced-footer {
        flex-direction: row;
        text-align: center;
        gap: 15px;
        padding: 15px;
    }

    .footer-logo {
        justify-content: center;
        margin-bottom: 10px;
    }

    .footer-text {
        display: none;
    }

    .social-icons {
        justify-content: center;
        gap: 10px;
    }
}
