.content_space{
    width: 100%;
    height: 100px;
}

.space_content{
    width: 100%;
    height: 50px;
}

/* Ajustes para tablets y pantallas medianas */
@media (max-width: 1024px) {
    .content_space{
        height: 200px;
    }
}

/* Ajustes para dispositivos móviles */
/* @media (max-width: 768px) {
  
} */
