.screenshots-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.screenshots-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.screenshot-item {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.screenshot-item:hover {
    transform: scale(1.05);
}

.screenshot-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.screenshots-title {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2.5rem;
    color: #333;
}

@media (max-width: 768px) {
    .screenshots-container {
        padding: 1rem;
    }
    
    .screenshots-grid {
        grid-template-columns: 1fr;
    }
}