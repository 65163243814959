/* En tu archivo CSS, por ejemplo en App.css */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
/* 
.fredoka-<uniquifier> {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings: "wdth" 100;
} */


h1, h2, h3, h4, h5, h6, p, a, span {
  color: rgb(0, 0, 0);
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  font-family: 'SF Pro Text', 'SF Pro Icons', 'AOS Icons', 'Helvetica Neue', Helvetica, Arial, sans-serif, system-ui;
}

body::before {
  --line: color-mix(in lch, canvasText 25%, transparent);
  --size: 40px;
  content: '';
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: linear-gradient(90deg, var(--line) 1px, transparent 1px var(--size)) 0 -5vmin / var(--size) var(--size),
              linear-gradient(var(--line) 1px, transparent 1px var(--size)) 0 -5vmin / var(--size) var(--size);
  mask: linear-gradient(-15deg, transparent 40%, white);
  top: 0;
  z-index: -1;
}

.Txt_center{
  text-align: center;
}