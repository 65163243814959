.section_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box; /* Asegura que el padding no exceda el ancho */
}

.section_header {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 20px;
    padding: 20px;
}

.section_image {
    flex: 1;
    display: flex;
    justify-content: center;
}

.section_header h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

.section_header span {
    font-size: 18px;
    line-height: 1.5;
    max-width: 500px;
}

/* Tamaño máximo y mínimo para la imagen */
.section_image img {
    max-width: 100%;
    height: auto;
    border-radius: 25px;
    max-height: 400px; /* Tamaño máximo para pantallas grandes */
    min-height: 200px; /* Tamaño mínimo */
}

/* Responsive Design */

/* Ajustes para tablets y pantallas medianas */
@media (max-width: 1024px) {
    .section_content {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 60vh;
    }

    .section_header {
        margin: 10px 0;
        padding: 0;
        text-align: center;
    }

    .section_image {
        justify-content: center;
    }

    .section_header h1 {
        font-size: 28px;
    }

    .section_header span {
        font-size: 16px;
        max-width: 100%;
    }

    .section_image img {
        max-height: 450px; /* Ajuste de tamaño para pantallas medianas */
    }
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
    .section_content {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .section_header h1 {
        font-size: 24px;
    }

    .section_header span {
        font-size: 14px;
    }

    .section_image img {
        max-height: 350px; /* Tamaño máximo ajustado para móviles */
        min-height: 250px; /* Tamaño mínimo ajustado */
    }
}
