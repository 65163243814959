.service-container {
    display: flex;
    justify-content: space-between;
    align-items: stretch; /* Asegura que todas las cards tengan la misma altura */
    gap: 20px; /* Espacio entre las columnas */
    flex-wrap: wrap; /* Permite que las columnas se reorganicen en pantallas pequeñas */
    padding: 0 40px;
}

.service-card {
    flex: 1;
    min-width: 280px; /* Tamaño mínimo para las tarjetas */
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: scale(1.05); /* Efecto hover */
}

.service-card h2 {
    font-size: 24px;
    margin: 15px 0;
}

.service-card p {
    font-size: 16px;
       color: #555;
}

/* Iconos */
.service-card svg {
    font-size: 48px;
    color: #0d72ad; /* Color de los íconos */
    margin-bottom: 10px;
}

/* Media Query para pantallas pequeñas */
@media (max-width: 768px) {
    .service-container {
        flex-direction: column;
        gap: 30px; /* Aumenta el espacio entre las tarjetas en dispositivos móviles */
        padding: 0 20px;
    }
}

/* Estructura para la sección "Por qué elegirnos" */
.why-choose-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 40px;
}

/* Para el contenedor que tiene la imagen y el contenido */
.why-choose-image {
    flex: 1;
    display: flex;
    justify-content: center;
}

.why-choose-image img {
    max-width: 100%;
    height: auto;
    border-radius: 25px;
}

.why-choose-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.why-choose-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.why-choose-item svg {
    font-size: 36px;
    color: #FFD700; /* Color dorado para destacar los íconos */
}

.why-choose-item p {
    font-size: 18px;
    color: #333;
}

/* Media query para pantallas pequeñas */
@media (max-width: 768px) {
    .why-choose-container {
        flex-direction: column;
        padding: 0 20px;
    }

    .why-choose-image,
    .why-choose-content {
        flex: none;
        width: 100%;
    }

    .why-choose-item {
        justify-content: center;
    }
}
